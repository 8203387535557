export const environment = {
  name: 'dev',
  production: false,
  baseURLExtranetAPI: 'https://app-dev.inter-nord.com/api/extranet/v1.0',
  baseURLCorporateAPI: 'https://app-dev.inter-nord.com/api/corporate/v1.0',
  storageSecretKey: 'kcsOOYqpHio5Z8UdjVbWmoK0uoP59s8Y',
  workedHoursRangeDays: 7,
  googleMapAPIKey: 'AIzaSyB9XKKFDsBLtSgrXtU_qDKSo0xh3syxsOc',
  googleMapAPIVersion: '3.59',
  timeDelayPrivateZoneLoginInMinutes: 30
};


export const aws = {
  aws_project_region: 'ca-central-1',
  aws_cognito_region: 'ca-central-1',
  aws_user_pools_id: 'ca-central-1_gvIwfUbXn',
  aws_user_pools_web_client_id: '7c3c675cj8goq2v1g8fjvcbhc8',
  oauth: {
    domain: 'inter-nord-dev.auth.ca-central-1.amazoncognito.com',
    scope: [
      'phone',
      'email',
      'openid',
      'profile',
      'aws.cognito.signin.user.admin'
    ],
    redirectSignIn: 'https://app-dev.inter-nord.com/callback',
    redirectSignOut: 'https://app-dev.inter-nord.com/callback',
    responseType: 'code'
  }
};
